import React, { useEffect, useRef } from "react";
import ReactGA from "react-ga";
import { Route, Routes, useLocation } from "react-router-dom";
import ScrollReveal from "./utils/ScrollReveal";
import Privacy from "./views/Privacy";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Views
import About from "./views/About";
import Contact from "./views/Contact";
import Home from "./views/Home";
import Terms from "./views/Terms";
import ERP from "./views/erp";
import AppRouting from "./components/sections/AppRouting";

// Initialize Google Analytics
// ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Routes>
          <Route exact path="/" element={<LayoutDefault><Home /></LayoutDefault>}></Route>
          <Route exact path="/privacy" element={<LayoutDefault><Privacy /></LayoutDefault>}></Route>
          <Route exact path="/contact" element={<LayoutDefault><Contact /></LayoutDefault>}></Route>
          <Route exact path="/about" element={<LayoutDefault><About /></LayoutDefault>}></Route>
          <Route exact path="/erp" element={<LayoutDefault><ERP /></LayoutDefault>}></Route>
          <Route exact path="/terms" element={<LayoutDefault><Terms /></LayoutDefault>}></Route>
          <Route exact path="/shop/:id" element={<AppRouting />}></Route>
          {/* <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute
            exact
            path="/privacy"
            component={Privacy}
            layout={LayoutDefault}
          />
          <AppRoute
            exact
            path="/terms"
            component={Terms}
            layout={LayoutDefault}
          /> */}
        </Routes>
      )}
    />
  );
};

export default App;
