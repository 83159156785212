import React from 'react';
import Image from "../elements/Image";
const AppRouting = () => {
    return (
        <center>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h3>Get MyVillage from Google play and app store</h3>
                <a href="https://play.google.com/store/apps/details?id=com.cq.token">
                    <Image
                        className="has-shadow"
                        src={require("./../../assets/images/google-play-badge.png")}
                        alt="Hero"
                        width={300}
                        height={300}
                    />
                </a>
                <a href="https://apps.apple.com/in/app/my-villages/id1609699729">
                    <Image
                        className="has-shadow"
                        src={require("./../../assets/images/apple.png")}
                        alt="Hero"
                        width={260}
                        height={260}
                    />
                </a>
                <br />
                <p>Website is under construction, soon you will view business here</p>
                <p>Thank you for your corporation</p>
            </div>
        </center>
    );
};

export default AppRouting;
