import classNames from "classnames";
import React from "react";
import { SectionTilesProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
import HOSPITALS from "./../../assets/images/hospital.svg";
import HOTEL from "./../../assets/images/hotel.svg";
import POLICE from "./../../assets/images/police.svg";
import REST from "./../../assets/images/rest.svg";
import TAXI from "./../../assets/images/taxi.svg";
import VISA from "./../../assets/images/visa.svg";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: 'What is "MyVillage" app',
    paragraph:
      '“My Village” App is an easy to use e-commerce app, which helps rural or urban , local or global shops do their business online, all for FREE. “My Village” app does not charge any registration fees, listing fees, or order fees. It is designed to empower businesses of all sizes and at all locations, enabling them to connect with customers globally. The same app can be used by a Buyer and Seller.',
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={HOTEL}
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Worldwide Visibility</h4>
                  <p className="m-0 text-sm">
                    Effortlessly showcase your products/services with captivating pictures, engaging videos, and product details, making your business be visible worldwide, effortlessly connecting with global markets.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img
                      src={REST}
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Absolutely Free!!!</h4>
                  <p className="m-0 text-sm">
                    Experience the power of My Village App without any financial burden... it's absolutely FREE! Unlock a world of opportunities for your business at no cost, and embrace the digital transformation with ease.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={VISA}
                      alt="Features tile icon 03"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">AI Powered Listing </h4>
                  <p className="m-0 text-sm">
                    Maximize your business impact with AI powered performance-based listing and visibility. Your performance is in your hands, good reviews will automatically highlight your business on the app,and help your online business grow.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={TAXI}
                      alt="Features tile icon 04"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Buy from Multiple Shop & Locations</h4>
                  <p className="m-0 text-sm">
                    Enjoy a flexible shopping experience with My Village App's innovative shopping cart. Seamlessly pick items from different shops, based out of different locations, creating a unified and convenient shopping experience tailored to your unique preferences.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={HOSPITALS}
                      alt="Features tile icon 05"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Shops/Events NearMe</h4>
                  <p className="m-0 text-sm">
                    My Village App's location-based search feature lets you find shops & events wherever you go. Explore businesses & events around you, from best shops, eateries & vibrant events,ensuring you never miss out on great local finds
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={POLICE}
                      alt="Features tile icon 06"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Transparent & Agile Shopping</h4>
                  <p className="m-0 text-sm">
                    Enables direct communication between buyers and sellers, with no middle man. Get map directions, ensuring you reach your desired shop effortlessly, also get unedited product reviews for better shopping experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
